import React from "react"
import classNames from 'classnames';

import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import '../styles/index.css';
import styles from '../styles/customers.module.css';

import Layout from "../components/layout";

import HeaderImage from '../components/imageHeader';

const Cliente = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout titlePage={intl.messages['clientes.title']}>

        <HeaderImage title={intl.messages['clientes.title']} />

        <div className={classNames("container", styles.customersContainer)}>

          <main>
            <div className={styles.boxContDescription}>
              <p className={styles.description}>
                {intl.messages['clientes.texto1']}
              </p>
              <p className={styles.description}>
                {intl.messages['clientes.texto2']}
              </p>
              <p className={styles.description}>
                {intl.messages['clientes.texto3']}
              </p>
            </div>

            <h4>CLIENTES DE CONSULTORIA EMPRESARIAL</h4>

            <div className={styles.boxContCustomers}>
              {data.allStrapiCliente.edges.map(({ node }) => (
                <div className={styles.boxImgFlex}>
                  <figure key={node.id}>
                    <img src={node.imagem.localFile.publicURL} className={styles.clienteImage} alt={node.descricao} />
                  </figure>
                </div>
              ))}
            </div>
            {/* Link do Whatsapp */}
            <div className="whatsAppLogo">
                        <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                            <img src="/wpp.svg" alt="" />
                        </a>
                    </div>
          </main>
        </div>

      </Layout>
  )
}

export const query = graphql`
query{
  allStrapiCliente {
    edges {
      node {
        descricao
        imagem {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`

export default Cliente
